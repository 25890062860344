<template>
<div>

    <!-- Filters -->
    <template>
        <b-card no-body class="p-1">
            <b-card-header class="pb-50">
                <h5>
                    Filters
                </h5>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="4" class="mb-md-0 mb-2">
                        <label>{{ $t('Asset')}}</label>
                        <vue-autosuggest v-model="searchQueryAssets" :suggestions="filteredOptionsAsset" :input-props="inputPropsAsset" @selected="selectHandlerAsset" @input="onInputChangeAsset">
                            <template slot-scope="{suggestion}">
                                <span class="my-suggestion-item">{{ suggestion.item.plate ? suggestion.item.plate : suggestion.item.chassisNumber }}</span>
                            </template>
                        </vue-autosuggest>
                    </b-col>
                    <b-col cols="12" md="4" class="mb-md-0 mb-2">
                        <label>{{ $t('Date Start')}}</label>
                        <flat-pickr v-model="dateStart" class="form-control" />
                    </b-col>
                    <b-col cols="12" md="4" class="mb-md-0 mb-2">
                        <label>{{ $t('Date End')}}</label>
                        <flat-pickr v-model="dateEnd" class="form-control" />
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </template>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

        <div class="m-2">

            <!-- Table Top -->
            <b-row>

                <!-- Per Page -->
                <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                    <label>{{ $t('Show')}}</label>
                    <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                    <label>{{ $t('entries')}}</label>
                </b-col>

                <!-- Search -->
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                        <b-dropdown  variant="primary">
                            <template #button-content>
                                {{ $t('Add Fuelling')}}
                            </template>
                            <b-dropdown-item :to="{ name: 'register-fuelling' }">{{ $t('Add Fuelling')}}</b-dropdown-item>
                            <b-dropdown-item v-b-tooltip.hover :title="titleFuellingBtn" :to="{ name: 'fuelling-bulk' }">{{ $t('Add Fuelling Bulk')}}</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </b-col>
            </b-row>

        </div>

        <b-table ref="refFuellingListTable" class="position-relative" :items="fetchAllFuelling" responsive :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty :empty-text="emptyText" :sort-desc.sync="isSortDirDesc">

            <!-- Column: Identifier -->
            <template #head(plate)>
                {{ $t('Identifier')}}
            </template>
            <template #cell(assetId)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.plate ? data.item.plate : data.item.chassisNumber }}
                </div>
            </template>

            <!-- Column: Date -->
            <template #head(dateTime)>
                {{ $t('Date')}}
            </template>
            <template #cell(dateTime)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ new Date(data.item.dateTime).toLocaleDateString()  }}
                </div>
            </template>

            <!-- Column: liters -->
            <template #head(liters)>
                {{ $t('Liters')}}
            </template>
            <template #cell(liters)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.liters }}
                </div>
            </template>

            <!-- Column: value -->
            <template #head(value)>
                {{ $t('Value')}}
            </template>
            <template #cell(value)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.value }}
                </div>
            </template>

            <!-- Column: odometer -->
            <template #head(odometer)>
                {{ $t('Odometer')}}
            </template>
            <template #cell(odometer)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.odometer }}
                </div>
            </template>

            <!-- Column: hourmeter -->
            <template #head(hourmeter)>
                {{ $t('Hourmeter')}}
            </template>
            <template #cell(hourmeter)="data">
                <div class="font-weight-bold d-block text-nowrap">
                    {{ data.item.hourmeter }}
                </div>
            </template>

            <!-- Column: Actions -->
            <template #head(actions)>
                {{ $t('Actions')}}
            </template>
            <template #cell(actions)="data">
                <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                    <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>

                    <b-dropdown-item :to="{ name: 'register-fuelling', params: { id: data.item.id } }">
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">{{ $t('Edit')}}</span>
                    </b-dropdown-item>

                    <b-dropdown-item @click="modalDelete(data.item.id)">
                        <feather-icon icon="Trash2Icon" />
                        <span class="align-middle ml-50">{{ $t('Delete')}}</span>
                    </b-dropdown-item>
                </b-dropdown>
            </template>

        </b-table>
        <div class="mx-2 mb-2">
            <b-row>

                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                    <span class="text-muted"> {{ $t('Showing')}} {{ dataMeta.from }} {{ $t('to')}} {{ dataMeta.to }} {{ $t('of')}} {{ dataMeta.of }} {{ $t('entries')}}</span>
                </b-col>
                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                    <b-pagination v-model="currentPage" :total-rows="totalFuelling" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>

                </b-col>

            </b-row>
        </div>
    </b-card>
</div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {
    VueAutosuggest
} from 'vue-autosuggest'
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import {
    avatarText
} from '@core/utils/filter'
//import UsersListFilters from './components/UsersListFilters.vue'
import useFuellingList from './useFuellingList'
import managementStoreModule from '../managementStoreModule'

export default {
    components: {
        //UsersListFilters,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BCardHeader,
        BCardBody,

        vSelect,
        flatPickr,
        VueAutosuggest
    },
    data() {
        return {
            //Datas autocomplete asset

            datasuggestAsset: [],
            filteredOptionsAsset: [],
            inputPropsAsset: {
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: "Select asset ...",
            },
            limit: 3,
            selectedAsset: null,
            emptyText: this.$t('No matching records found'),
            titleFuellingBtn: this.$t('Add Fuelling Bulk')

        }
    },
    methods: {

        selectHandlerAsset(option) {
            this.selectedAsset = option.item
            this.filteredOptionsAsset = []
            this.searchQueryAssets = option.item.plate
            this.assetId = option.item.id

            console.log(`option selecionado `, option.item)
        },

        onInputChangeAsset(text) {
            if (text === '' || text === undefined || text.length < 3) {
                this.filteredOptionsAsset = []
                if (this.searchQueryAssets == 0) this.assetId = ''
                return
            }

            console.log('search q', this.searchQueryAssets)

            store
                .dispatch('app-management/fetchAssets', {
                    q: this.searchQueryAssets,

                })
                .then(response => {
                    console.log('resp auto', response.data.data)
                    this.filteredOptionsAsset = [{
                        data: response.data.data,
                    }]
                })
                .catch((e) => {
                    console.log(e);

                })
        },

        modalDelete(myId) {
            this.$swal({
                title: this.$t('Are you sure?'),
                text: this.$t("You can't revert your action"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('No'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: {
                    cancelButton: 'cancelBtn',
                    confirmButton: 'mr-1',
                }
            }).then((result) => {
                if (result.value) {
                    console.log('ID', myId)
                    this.deleteUser(myId)

                } else {}
            })
        },

        deleteUser(myId) {
            store.dispatch('app-management/deleteFuelling', {
                    id: myId
                })
                .then(response => {
                    this.$swal(this.$t("Deleted"), `${this.$t("You successfully deleted")}`, 'success')
                    this.refetchData()

                })
                .catch(error => {
                    this.$swal('Error', 'Your fuelling is still intact', 'error')
                    console.log(error);
                })
        }
    },
    setup() {
        const MANAGEMENT_APP_STORE_MODULE_NAME = 'app-management'

        // Register module
        if (!store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.registerModule(MANAGEMENT_APP_STORE_MODULE_NAME, managementStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(MANAGEMENT_APP_STORE_MODULE_NAME)
        })

        const isAddNewUserSidebarActive = ref(false)

        const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

        const roleOptions = [{
                label: 'Admin',
                value: 'admin'
            },
            {
                label: 'Author',
                value: 'author'
            },
            {
                label: 'Editor',
                value: 'editor'
            },
            {
                label: 'Maintainer',
                value: 'maintainer'
            },
            {
                label: 'Subscriber',
                value: 'subscriber'
            },
        ]

        const planOptions = [{
                label: 'Basic',
                value: 'basic'
            },
            {
                label: 'Company',
                value: 'company'
            },
            {
                label: 'Enterprise',
                value: 'enterprise'
            },
            {
                label: 'Team',
                value: 'team'
            },
        ]

        const statusOptions = [{
                label: 'Pending',
                value: 'pending'
            },
            {
                label: 'Active',
                value: 'active'
            },
            {
                label: 'Inactive',
                value: 'inactive'
            },
        ]

        const {
            fetchAllFuelling,
            tableColumns,
            perPage,
            currentPage,
            totalFuelling,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refFuellingListTable,
            dateStart,
            dateEnd,
            assetId,
            refetchData,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            // Extra Filters
            //roleFilter,
            //planFilter,
            //statusFilter,
        } = useFuellingList()

        return {

            // Sidebar
            isAddNewUserSidebarActive,

            fetchAllFuelling,
            tableColumns,
            perPage,
            currentPage,
            totalFuelling,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refFuellingListTable,
            dateStart,
            dateEnd,
            assetId,
            refetchData,

            // Filter
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            roleOptions,
            planOptions,
            statusOptions,
            user

            // Extra Filters
            //roleFilter,
            //planFilter,
            //statusFilter,
        }
    },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style><style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
